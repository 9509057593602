:root {
  --mainbg: #1091fc;
  --mainColor: #b2d847;
  --subColor: ;
  --mainbgLight: #e3f2fd;
  /* --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1; */
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.childrenPageHeader {
  padding-top: 5rem;
}

.headerImageWrapper {
  padding: 1rem;
  position: relative;
}

.headerImageWrapper img {
  width: 100%;
}

.headerImageWrapper:after {
  position: absolute;
  content: '';
  height: 60%;
  width: 60%;
  background-color: var(--mainbg);
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 0px 0px 10px 0px;
}
.headerImageWrapper:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 60%;
  background-color: var(--mainColor);
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 10px 0px 0px 10px;
}

.headerText {
  height: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 2rem;
}

/* cutom to only this app */
.sponsorshipProvides h4 {
  text-align: center;
  padding-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 200;
  font-size: 2rem;
}
.sponsorshipProvides ul {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.sponsorshipProvides ul li {
  margin-bottom: 1rem;
  background-color: var(--mainbgLight);
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.spIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.spIcons img {
  height: 70px;
  width: 70px;
}

.testmonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testmonials h2,
.becomeSponsor h2 {
  margin-bottom: 2rem;
  position: relative;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.testmonials h2:after,
.becomeSponsor h2:after {
  position: absolute;
  content: '';
  height: 4px;
  width: 60%;
  background-color: var(--mainColor);
  left: 0;
  bottom: -0.5rem;
  z-index: -1;
  border-radius: 10px;
}

.sponsorCarousel {
  padding-top: 3rem;
}

.testimonialWrapper {
  height: 100%;
  padding-bottom: 3rem;
}

.testimonial {
  background-color: var(--mainbgLight);
  padding: 1rem;
  border-radius: 10px;
  height: 180px;
  position: relative;
  margin-bottom: 22px;
}

.testimonial:after {
  content: '';
  top: 100%;
  left: 70px;
  width: 0px;
  height: 0px;
  position: absolute;
  border-top: 20px solid #e3f2fd;
  border-right: 18px solid transparent;
  border-left: 15px solid transparent;
}

.testmonialContent {
  height: 100%;
  overflow-y: scroll;
}

.spaceSince {
  display: block;
  padding-top: 1rem;
}

.minSponsor {
  height: 50px;
  width: 50px;
  margin-left: 60px;
}

.minSponsor h6 {
  white-space: nowrap;
  padding-top: 0.5rem;
}

.minSponsor img {
  border-radius: 50%;
}

.sendgiftContatiner {
  background-color: var(--mainbgLight);
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.sendGift {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sendGift h4 {
  text-align: start;
}

.sendGift:last-of-type {
  align-items: center;
}

.sendGift .imageWrapper {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.becomeSponsor {
  padding-top: 7rem;
  text-align: center;
}

.chooseChild {
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.chooseChild ul {
  padding-left: 2rempx;
  list-style-type: none;
}
.chooseChild ul li {
  position: relative;
  padding-bottom: 0.5rem;
}

.chooseChild ul li:before {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  background-color: var(--mainColor);
  left: -1rem;
  bottom: -0.5rem;
  z-index: -1;
  border-radius: 10px;
  top: 20%;
}

@media (max-width: 991.98px) {
  .childrenPageHeader {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .headerImageWrapper {
    margin-bottom: 2rem;
  }
  .sponsorshipProvides h4 {
    font-size: 1.6rem;
  }
}

@media (max-width: 575.98px) {
  .sponsorshipProvides ul {
    grid-template-columns: 1fr;
    padding-left: 0px;
  }
}
