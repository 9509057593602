:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}

.childCard {
  border-radius: 0px;
  margin-top: 0rem;
  margin-bottom: 0rem;
  text-align: center;
  padding-bottom: 0rem;
  height: 100%;
}
.childCardSponsored {
  filter: grayscale(1);
}
.childCardSponsored:hover {
  filter: grayscale(0);
}

.childCardImage {
  border-radius: 50%;
  margin-top: -100px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.childCardTitle {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  line-height: 1.2;
}

.childCardStory {
  display: block;
  background-color: transparent;
  color: inherit;
  border: none;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}
.childCardStory:hover {
  background-color: transparent;
  text-decoration: underline;
  color: var(--mainbg);
}

.storyIcon {
  color: var(--mainbg);
  padding-right: 0.5rem;
}

.childCardButton,
.editAChildButton,
.deleteAChildButton {
  background-color: var(--mainColor);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.375rem 1.5rem;
  border-radius: 50rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
}
.childCardSponsored .childCardButton {
  background-color: var(--mainbg);
  color: var(--white);
}

.childCardButton:hover {
  background-color: var(--mainbg);
  color: var(--white);
}

.editAChildButton {
  background-color: var(--mainbgLight);
}

.sponsorStatus {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sponsorStatus input {
  margin-right: 0.5rem;
  accent-color: var(--mainbg);
}

.editAChildButton:hover {
  background-color: var(--mainbg);
  color: var(--white);
}
.deleteAChildButton {
  background-color: var(--subColorLight);
}
.deleteAChildButton:hover {
  background-color: var(--subColor);
  color: var(--white);
}
