:root {
  --mainbg: #1091fc;
  --mainColor: #b2d847;
  --subColor: ;
  --mainbgLight: #e3f2fd;
  /* --mainColorLight: #fcb140a8; */
  --subColorLight: #656565;
  --white: #fff;
  --pale: #4b4a4a;
  --dark: #010101;
}
.navbarMain {
  font-family: 'Inter', sans-serif;
  font-family: 'Questrial', sans-serif;
  padding-top: 0px;
  padding-bottom: 0px;
  position: sticky;
  background-color: var(--mainbgLight);
  z-index: 400;
  box-sizing: border-box;
  box-shadow: 0px 4px 22px 2px rgb(35 6 6 / 50%);
  transition: 0.3s;
}

.sizeController {
  width: 100%;
  max-width: 1600px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3%;
  padding-right: 3%;
  height: 100%;
  padding-top: 0.4rem;
  justify-content: center;
}
.navbar-toggler-icon {
  color: #fff;
}

.fixedNav {
  background-color: var(--mainbg);
}

.brandLogo {
  height: 70px;
}

.myToggler {
  border: none;
  width: 43px;
  height: 38px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  padding: 5px;
}

.myToggler:hover {
  background-color: var(--mainColor);
}

.myToggler:focus,
.myToggler:active {
  border: none;
  box-shadow: none;
}

.animatedIcon1 {
  width: 30px;
  height: 24px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.animatedIcon1 span {
  border: transparent;
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.15s ease-in-out;
}

.animatedIcon1 span {
  background: #1091fc;
}

.animatedIcon1 span:nth-child(1) {
  top: 0px;
}

.animatedIcon1 span:nth-child(2) {
  top: 10px;
}

.animatedIcon1 span:nth-child(3) {
  top: 20px;
}

.scrollabeNav {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.scrollableLinks {
  height: fit-content;
  margin-left: auto;
}

.navbarLinks {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  font-size: 15px;
  font-family: Questrial;
  text-decoration: none;
  color: var(--subColorLight) !important;
  font-weight: bold;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.navbarLinks:hover {
  background-color: var(--mainColor);
  color: var(--white);
}
.navbarLinks:hover:before {
  width: 50%;
  opacity: 1;
}
.navbarLinks:hover:after {
  width: 50%;
  opacity: 1;
}

.navbarMain .donate {
  padding: 0.8rem 1rem;
  background-color: var(--mainColor);
  color: var(--white);
  border-radius: 5px;
  transition: 0.3s;
  text-decoration: none;
  position: relative;
  font-weight: bold;
}

.buttonIcon {
  height: 30px;
  padding-right: 5px;
}
.buttonIcon svg {
  fill: var(--white);
}

.navbarMain .donate:hover {
  background-color: var(--mainbg);
  color: var(--white);
}

@media (max-width: 991.98px) {
  .navbarMain {
    height: 97px;
  }
  .sizeController {
    flex-direction: column;
    position: relative;
  }
  .myToggler {
    position: absolute;
    right: 2rem;
    z-index: 100;
  }

  .scrollabeNav {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbarLinks {
    width: 90%;
    margin-bottom: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbarMain .donate {
    display: inline-block;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
  }
  .navbarLinks::after {
    display: none;
    top: 100;
  }
  .navbarLinks::before {
    height: 3px;
    top: 70%;
    left: 0;
  }
}

@media (max-width: 575.98px) {
  .navbarMain {
    height: 72px;
  }
  .brandLogo {
    height: 50px;
  }
}
