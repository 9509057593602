.thankyou {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.thankyou h1 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--mainColor);
  margin-bottom: 1rem;
}

.complete {
  font-size: 3rem;
  font-weight: 1;
  color: var(--mainbg);
  margin-bottom: 1rem;
  height: 100px;
  width: 100px;
  border: 2px solid var(--mainbg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.loading {
  height: 100px;
  width: 100px;
  border: 4px solid;
  border-color: var(--mainbg) var(--mainbg) var(--mainbg) #494949;
  border-radius: 50%;
  animation: spin 1.3s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.thankyouMessage {
  font-size: 1rem;
  font-weight: 400;
  max-width: 700px;
  color: var(--white);
  margin-bottom: 0.9rem;
}

.links {
  padding-top: 2rem;
}

.thankyouLinks {
  padding: 0.8rem 1rem;
  background-color: var(--mainColor);
  color: var(--white);
  border-radius: 5px;
  transition: 0.3s;
  text-decoration: none;
  position: relative;
  font-weight: bold;
  margin: 1rem 0.4rem;
  cursor: pointer;
  outline: none;
  border: none;
}

.thankyouLinks:hover {
  background-color: var(--mainbg);
  color: var(--white);
}

@media (max-width: 767.98px) {
  .thankyou {
    justify-content: flex-start;
    padding-top: 7rem;
  }
}

.childImage {
  padding: 1rem;
  width: 275px;
  height: 275px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.childImage img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
